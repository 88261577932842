<template>
  <div style="max-width: 600px; height: calc(100%); width: 90%; margin: auto">
    <div style="position: relative; height: calc(100% - 128px); overflow-y: auto" flat width="100%">
      <v-card-title>
        Create Testing Lead
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field label="FIRST NAME" v-model="params.firstName"></v-text-field>
          <v-text-field label="SSN" v-model="params.ssn" v-mask="'###-##-####'"></v-text-field>
          <v-text-field label="HOME PHONE" v-model="params.phone" v-mask="'(###) ###-####'"></v-text-field>
          <v-text-field label="EMAIL" v-model="params.email"></v-text-field>
          <v-text-field label="ROUTING NUMBER" v-model="params.routing"></v-text-field>
          <v-text-field label="ACCOUNT NUMBER" v-model="params.account"></v-text-field>
        </v-form>
      </v-card-text>
    </div>
    <v-card-actions style="position: absolute; width: 90%; bottom: 78px;max-width: 600px;">
      <v-btn fab v-if="url !== ''" :href="url" target="_blank">
        Go
      </v-btn>
    </v-card-actions>
    <div style="position: absolute; width: 90%; bottom: 20px;max-width: 600px;">
      <v-btn class="width-50p" outlined bottom @click="reset">
        Refresh
      </v-btn>
      <v-btn class="width-50p" @click="send" color="primary">
        Send
      </v-btn>
    </div>

    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>

    <div>
      <v-card-text style="visibility: hidden" class="bold-f-s text-center p-t-24 pc-title">
        Title
      </v-card-text>
      <div class="pc-container">
        <div class="pc-content">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { OpenApi } from '@/api/openapi.js'
import { Tool, DataType } from '@/js/core'

const tool = new Tool()
export default {
  data () {
    return {
      request: '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<REQUEST>\n' +
        '  <REFERRAL>\n' +
        '    <STOREKEY/>\n' +
        '    <REFURL>https://www.fast5kloans.com</REFURL>\n' +
        '    <IPADDRESS>70.114.69.116</IPADDRESS>\n' +
        '    <TIERKEY/>\n' +
        '    <AFFID>16565958221</AFFID>\n' +
        '    <SUBID>728124</SUBID>\n' +
        '    <TEST>false</TEST>\n' +
        '  </REFERRAL>\n' +
        '  <CUSTOMER>\n' +
        '    <PERSONAL>\n' +
        '      <REQUESTEDAMOUNT>800</REQUESTEDAMOUNT>\n' +
        '      <SSN>{ssn}</SSN>\n' +
        '      <DOB>1996-06-03</DOB>\n' +
        '      <GENDER>U</GENDER>\n' +
        '      <FIRSTNAME>{firstName}</FIRSTNAME>\n' +
        '      <LASTNAME>TEXT</LASTNAME>\n' +
        '      <ADDRESS>906 Appleblogfger Ln</ADDRESS>\n' +
        '      <ADDRESS2/>\n' +
        '      <CITY>MESQUITE</CITY>\n' +
        '      <STATE>AZ</STATE>\n' +
        '      <ZIP>85001</ZIP>\n' +
        '      <HOMEPHONE>{phone}</HOMEPHONE>\n' +
        '      <OTHERPHONE>8208200302</OTHERPHONE>\n' +
        '      <DLSTATE>TX</DLSTATE>\n' +
        '      <DLNUMBER>44101902</DLNUMBER>\n' +
        '      <CONTACTTIME>A</CONTACTTIME>\n' +
        '      <ADDRESSMONTHS>0</ADDRESSMONTHS>187\n' +
        '      <ADDRESSYEARS>1</ADDRESSYEARS>\n' +
        '      <RENTOROWN>R</RENTOROWN>\n' +
        '      <ISMILITARY>false</ISMILITARY>\n' +
        '      <ISCITIZEN>true</ISCITIZEN>\n' +
        '      <OTHEROFFERS>false</OTHEROFFERS>\n' +
        '      <EMAIL>{email}</EMAIL>\n' +
        '    </PERSONAL>\n' +
        '    <EMPLOYMENT>\n' +
        '    <INCOMETYPE>E</INCOMETYPE>\n' +
        '      <PAYTYPE>D</PAYTYPE>\n' +
        '      <EMPMONTHS>0</EMPMONTHS>\n' +
        '      <EMPYEARS>1</EMPYEARS>\n' +
        '      <EMPNAME>Acme health care</EMPNAME>\n' +
        '      <EMPADDRESS/>\n' +
        '      <EMPADDRESS2/>\n' +
        '      <EMPCITY/>\n' +
        '      <EMPSTATE>TX</EMPSTATE>\n' +
        '      <EMPZIP>85001</EMPZIP>\n' +
        '      <EMPPHONE>(802)532-1400</EMPPHONE>\n' +
        '      <EMPPHONEEXT/>\n' +
        '      <HIREDATE>2018-08-22</HIREDATE>\n' +
        '      <EMPTYPE>S</EMPTYPE>\n' +
        '      <JOBTITLE>Unknown</JOBTITLE>\n' +
        '      <PAYFREQUENCY>S</PAYFREQUENCY>\n' +
        '      <NETMONTHLY>1700</NETMONTHLY>\n' +
        '      <LASTPAYDATE>2021-01-01</LASTPAYDATE>\n' +
        '      <NEXTPAYDATE>2019-08-30</NEXTPAYDATE>\n' +
        '      <SECONDPAYDATE>2019-09-13</SECONDPAYDATE>\n' +
        '    </EMPLOYMENT>\n' +
        '    <BANK>\n' +
        '      <BANKNAME>JPMORGAN CHASE BANK</BANKNAME>\n' +
        '      <BANKPHONE>(111)111-1114</BANKPHONE>\n' +
        '    <ACCOUNTTYPE>C</ACCOUNTTYPE>\n' +
        '      <ROUTINGNUMBER>{routing}</ROUTINGNUMBER>\n' +
        '      <ACCOUNTNUMBER>{account}</ACCOUNTNUMBER>\n' +
        '      <BANKMONTHS>0</BANKMONTHS>\n' +
        '      <BANKYEARS>1</BANKYEARS>\n' +
        '    </BANK>\n' +
        '    <REFERENCES/>\n' +
        '  </CUSTOMER>\n' +
        '</REQUEST>',
      params: {
        firstName: '',
        ssn: '',
        phone: '',
        email: '',
        routing: '',
        account: ''
      },
      selected: 'NSL',
      url: '',
      overlay: false,
      portfolioList: DataType.Portfolio,
      portfolioTokenList: DataType.portfolioTokenList
    }
  },
  watch: {
    selected (val) {
      this.params.firstName = val + this.RandomNum(100, 1000)
    }
  },
  created () {
    this.$vuetify.theme.themes.light.primary = '#00234B'
    const query = window.location.href
    this.selected = query.substring(query.lastIndexOf('/') + 1)
    this.reset()
  },
  methods: {
    send () {
      this.url = ''
      const $this = this
      $this.params.ssn = $this.params.ssn.replaceAll(/\D/g, '')
      $this.params.phone = $this.params.phone.replaceAll(/\D/g, '')
      const str = $this.request.format($this.params)
      const payload = {
        leadId: 32,
        leadProviderId: 8,
        channelId: 107,
        rawData: str,
        features: {
          instantLitigantVerification: 1,
          category: 1,
          scope: 2,
          quality: 24
        },
        independent: true
      }
      if (tool.isNotEmpty($this.selected)) {
        const selectedPortfolio = $this.portfolioList.filter(item => {
          return item.code === $this.selected
        })
        if (tool.isNotEmpty(selectedPortfolio)) {
          $this.portfolioTokenList.map(item => {
            if (item.portfolioId === selectedPortfolio[0].value) {
              tool.setItem('AT', item.portfolioToken)
            }
          })
        }
      }

      $this.overlay = true
      OpenApi.sendLead(payload, function (e) {
        $this.overlay = false
        if (tool.isNotEmpty(e?.invitationUrl)) {
          $this.url = e.invitationUrl
        } else {
          $this.$swal({ title: 'REJECTED', width: 300 })
        }
      }, (error) => {
        $this.$swal({
          type: 'warning',
          text: error.message
        }).then(function () {
          $this.overlay = false
        })
      })
    },
    RandomNum (Min, Max) {
      const Range = Max - Min
      const Rand = Math.random()
      if (Math.round(Rand * Range) === 0) {
        return Min + 1
      }
      return Min + Math.round(Rand * Range)
    },
    reset () {
      const $this = this
      $this.params.firstName = $this.selected + $this.RandomNum(100, 1000)
      $this.params.ssn = $this.RandomNum(100, 1000) + '-' + $this.RandomNum(10, 100) + '-' + $this.RandomNum(1000, 10000)
      $this.params.phone = '(' + $this.RandomNum(100, 1000) + ') ' + $this.RandomNum(100, 1000) + '-' + $this.RandomNum(1000, 10000)
      $this.params.email = 'email' + $this.RandomNum(1, 100000) + '@sztus.com'
      $this.params.routing = $this.RandomNum(100000000, 1000000000)
      $this.params.account = $this.RandomNum(100000000, 100000000000)
      $this.$forceUpdate()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
